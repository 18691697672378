import { debounce } from '../../utility/debounce';
import { initSwipe } from '../../utility/swipe';

const Classnames = {
  activeDot: `slider__dot_state_active`,
};

const Selectors = {
  root: `.slider`,
  grid: `.slider__grid`,
  item: `.slider__item`,
  dots: `.slider__dots`,
  dot: `.slider__dot`,
  prev: `.slider__control_type_prev`,
  next: `.slider__control_type_next`,
  canvas: `.slider__canvas`,
};

const Attributes = {
  slideTime: `data-slide-time`,
  autoSlide: `data-auto-slide`,
  step: `data-step`,
  gap: `data-gap`,
  count: `data-count`,
  noSwipe: `data-no-swipe`,
};

const Regexps = {
  activeDot: new RegExp(' *' + Classnames.activeDot, 'ig'),
};

document.querySelectorAll(Selectors.root).forEach(initSlider);

window.Slider = { init: initSlider };

function initSlider(sliderNode) {
  let gridNode = sliderNode.querySelector(Selectors.grid);
  let dotsNode = sliderNode.querySelector(Selectors.dots);
  let dotNodes = sliderNode.querySelectorAll(Selectors.dot);
  let prevNode = sliderNode.querySelector(Selectors.prev);
  let nextNode = sliderNode.querySelector(Selectors.next);
  let itemNodes = Array.from(sliderNode.querySelectorAll(Selectors.item));

  let slideTime = sliderNode.getAttribute(Attributes.slideTime);
  let withAutoSlide = sliderNode.hasAttribute(Attributes.autoSlide);
  let noSwipe = sliderNode.hasAttribute(Attributes.noSwipe);
  let count = itemNodes.length;
  let step;
  let gap;

  let activeIndex = 0;
  let autoSlideTimer;

  getStepAndGap();
  trackStepAndGap();
  copyNodes();
  setTransform();
  initControls();
  initDots();
  autoSlide();

  if (!noSwipe) {
    initSwipe(sliderNode, {
      forward: next,
      back: prev,
    });
  }

  function autoSlide() {
    if (autoSlideTimer) {
      clearTimeout(autoSlideTimer);
    }

    if (!withAutoSlide) return;

    autoSlideTimer = setTimeout(next, slideTime);
  }

  function setTransform() {
    if (step === 'auto') getStepAndGap();
    gridNode.style.transform = `translateX(calc(-${activeIndex}*${step} - ${activeIndex}*${gap}))`;
  }

  function goto(index) {
    if (index === activeIndex) return;

    activeIndex = index;

    setTransform();

    if (dotsNode) {
      for (let i = 0; i < dotNodes.length; i++) {
        dotNodes[i].className = dotNodes[i].className.replace(Regexps.activeDot, ``);

        if (i === activeIndex) {
          dotNodes[i].className += ` ${Classnames.activeDot}`;
        }
      }
    }

    autoSlide();
  }

  function prev(event) {
    if (event) {
      withAutoSlide = false;
    }

    if (activeIndex === 0) {
      goto(count - 1);
      return;
    }

    let prevIndex = activeIndex;
    activeIndex--;

    setTransform();

    if (dotsNode) {
      dotNodes[activeIndex].className += ` ${Classnames.activeDot}`;
      dotNodes[prevIndex].className = dotNodes[prevIndex].className.replace(Regexps.activeDot, '');
    }

    autoSlide();
  }

  function next(event) {
    if (event) {
      withAutoSlide = false;
    }

    if (activeIndex === count - 1) {
      goto(0);
      return;
    }

    let prevIndex = activeIndex;
    activeIndex++;

    setTransform();

    if (dotsNode) {
      dotNodes[activeIndex].className += ` ${Classnames.activeDot}`;
      dotNodes[prevIndex].className = dotNodes[prevIndex].className.replace(Regexps.activeDot, ``);
    }

    autoSlide();
  }

  function initDots() {
    if (!dotsNode) return;

    for (let i = 0; i < dotNodes.length; i++) {
      dotNodes[i].addEventListener('click', () => {
        withAutoSlide = false;
        goto(i);
      });
    }
  }

  function initControls() {
    if (prevNode) {
      prevNode.addEventListener('click', prev);
    }

    if (nextNode) {
      nextNode.addEventListener('click', next);
    }
  }

  function getStepAndGap() {
    let node = itemNodes[1] || itemNodes[0];
    let style = getComputedStyle(node);

    step = style.flexBasis;
    gap = style.marginLeft;
  }

  function trackStepAndGap() {
    let onResize = () => {
      getStepAndGap();
      setTransform();
    };

    window.addEventListener('resize', debounce(onResize, 250));
  }

  function copyNodes() {
    for (let itemNode of itemNodes) {
      gridNode.appendChild(itemNode.cloneNode(true));
    }
  }
}

export function initSwipe(node, handlers) {
  let x0 = 0;
  let isSwipe = false;

  node.addEventListener('mousedown', lock);
  node.addEventListener('touchstart', lock);

  node.addEventListener('mouseup', move);
  node.addEventListener('touchend', move);

  node.addEventListener('mousemove', drag);
  node.addEventListener('touchmove', drag);

  function lock(event) {
    x0 = unify(event).clientX;
    isSwipe = false;
  }

  function move(event) {
    let x1 = unify(event).clientX;
    let dx = x1 - x0;
    let direction = Math.sign(dx);

    if (Math.abs(dx) < 50) return;

    if (direction === -1) handlers.forward();
    else handlers.back();
  }

  function drag(event) {
    let x1 = unify(event).clientX;
    let dx = x1 - x0;

    if (Math.abs(dx) >= 50) event.preventDefault();
  }
}

function unify(event) {
  return event.changedTouches ? event.changedTouches[0] : event;
}
